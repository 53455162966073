import { useState } from "react";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import Button from "./Button";

export const TabPageCustom = ({
  tabArray,
  activeTab = 0,
  setActive = () => { },
  isFormDirty
}) => {
  const navigate = useNavigate();
  const [nextNav, setNextNav] = useState(false);
  const [showModalBackConfirm, setShowModalBackConfirm] = useState(false);
  const checkFormDirty = (nav, isFormDirty) => {
    setNextNav(nav);
    if (isFormDirty) {
      setShowModalBackConfirm(true)
      return;
    };

    changePage(nav);
  }

  const changePage = (nav) => {
    if (nav && (!setActive || setActive === null)) return navigate(nav)
    return setActive(nav)
  }

  return (
    <Row>
      <div className="tab-action">
        <div className="left">
          {tabArray.map((val, index) => {
            return (
              <div key={val.key || index} className={activeTab === index ? "active" : ""} onClick={() => checkFormDirty(val.nav, isFormDirty)}>
                <h3 className='p-15'>{val.title}</h3>
              </div>
            )
          })}
        </div>
      </div>

      <Modal
        title={"Stay on Page"}
        showDialog={showModalBackConfirm}
        showHeader={false}
      >
        <div className="text-center">
          <img
            className="m-auto"
            src={require('assets/images/modal-back-confirmation.png')}
            alt="modal-back-confirmation"
          />
          <h2 className="text-lg font-bold">Stay on Page?</h2>
          <p>Any data you’ve input on this page will be cleared if you close this screen. Do you want to continue?</p>
        </div>
        <div className="flex gap-2">
          <Button
            variant="primary-outline"
            className="rounded-full py-10 !mt-10 w-full"
            onClick={(e) => {
              e.preventDefault();
              setShowModalBackConfirm(false);
              navigate(nextNav);
            }}
          >
            Close Without Saving
          </Button>
          <Button
            variant="primary"
            className="bg-primary rounded-full py-10 !mt-10 w-full"
            onClick={(e) => {
              e.preventDefault();
              setShowModalBackConfirm(false);
            }}
          >
            Stay Here
          </Button>
        </div>
      </Modal>
    </Row>
  );
};

export default TabPageCustom;
