import { toast } from "react-toastify";
import { replaceDotWithComma } from "utils/helper";

export const maxInputValidation = (max, inputValue) => {
  if (inputValue.toString().length > max) {
    toast.error(`Maximum input length is ${max}`, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    return false;
  } else {
    return true;
  }
}

export const tonToKiloTon = (value) => {
  const valueInKilo = replaceDotWithComma((value || 0) / 1000);
  const splitValue = valueInKilo.split(",");
  return splitValue.length < 2
    ? thousandSeparator(splitValue[0])
    : thousandSeparator(splitValue[0]) + "," + splitValue[1];
}

export const cleaningThousandValue = (value) => {
  return (value.toString().replace(/\D/g, '')).toString().replaceAll(".", "");
}

export const cleanNonDigitCharacter = (value) => {
  let result = value.toString().replace(/[^\d]/g, '');  // Remove non-digit
  return result;
}

export const cleanNonDigitAndNonCommaCharacter = (value) => {
  let result = value.toString().replace(/[^\d,]/g, '');  // Remove non-digit, non-comma characters
  if (result === ',') return '';
  // Replace all commas after the first one
  let firstCommaIndex = result.indexOf(',');
  if (firstCommaIndex !== -1) {
    // Keep the first comma and remove all subsequent commas
    return result.substring(0, firstCommaIndex + 1) + result.slice(firstCommaIndex + 1).replace(/,/g, '');
  }
  return result; // If no comma is found, return the string as is
}

export function removeTrailingZeros(value) {
  // Remove trailing zeros from the string
  return value.replace(/0+$/, '');
}

export function addThousandSeparator(str, numberAfterComma = 2) {
  if (str === '0' || str === 0) return '0';
  const cleanedValue = cleanNonDigitAndNonCommaCharacter(str);
  // Find the first comma index
  let firstCommaIndex = cleanedValue.indexOf(',');

  // If a comma is found
  if (firstCommaIndex !== -1) {
    // Get the part of the string before the first comma
    let beforeComma = cleanedValue.substring(0, firstCommaIndex);

    // Format the part before the comma with thousand separators (dots)
    let formattedBeforeComma = thousandSeparator(beforeComma);
    // let formattedBeforeComma = thousandSeparator(beforeComma);

    // Get the part of the string after the first comma
    let afterComma = cleanedValue.substring(firstCommaIndex);
    let formattedAfterComma = (afterComma.substring(1, numberAfterComma + 1));

    // Return the combined string with the formatted part before the comma
    return formattedBeforeComma + ',' + formattedAfterComma;
  }

  // If no comma is found, return the string as is
  return thousandSeparator(cleanedValue);
}

export function clearThousandSeparator(str) {
  if (!str) return ''
  if (str === '0' || str === 0) return '0';
  const cleanedValue = cleanNonDigitAndNonCommaCharacter(str);
  // Find the first comma index
  let firstCommaIndex = cleanedValue.indexOf(',');

  // If a comma is found
  if (firstCommaIndex !== -1) {
    // Get the part of the string before the first comma
    let beforeComma = cleanedValue.substring(0, firstCommaIndex);
    let formattedBeforeComma = removeThousandSeparator(beforeComma);

    // Get the part of the string after the first comma
    let afterComma = cleanedValue.substring(firstCommaIndex + 1);
    let formattedAfterComma = removeTrailingZeros(afterComma);
    // Return the combined string with the formatted part before the comma
    return formattedBeforeComma + (formattedAfterComma ? ',' + formattedAfterComma : '');
  }
  // If no comma is found, return the string as is
  return removeThousandSeparator(cleanedValue);
}

export function formatStringNumberInputToDecimal(value) {
  if (!value) return 0;
  return parseFloat(clearThousandSeparator(value).toString().replace(",", "."));
}

export function formatDecimalToStringNumberInput(value, numberAfterComma = 2) {
  if (!value) return '0';
  return addThousandSeparator(value.toString().replace(".", ","), numberAfterComma);
}

export function roundedValueDecimal(value, amount = 2) {
  return Math.round(value * (10 ** amount)) / (10 ** amount);
}

export const thousandSeparator = (number) => {
  // Convert the number to an integer and then back to a string to handle cases like '00', '000' etc.
  // then convert number to string
  const normalized = number === "" ? "" : parseInt(number, 10).toString();
  let numStr = normalized === 0 || normalized === '0' ? normalized : normalized.toString().replace(/^0+/, '');
  // Use regex to match digits in groups of three, inserting a dot between them for thousand separator
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return numStr;
}

export const removeThousandSeparator = (numberString) => {
  return numberString === undefined || numberString === "" || numberString === '' ? 0 : parseInt(numberString?.toString().replaceAll('.', '') ?? 0);
}

export const isCalorieTypeAllowed = (port) => {
  return ['BIR', 'TIA'].includes(port)
}

export const defaultPortOrder = ["BIB", "TIA", "BIR", "BIR TR", "BIR BLC", "BEI", "PEL"];
export const defaultPortColorPalette = {
  "BIB": "#C50607",
  "BIB Trans": "#770304",
  "BIB DMO": "#C50607",
  "TIA": "#FA4E4D",
  "BIR": "#FF8180",
  "BIR TR": "#FF8180",
  "BIR BLC": "#FF8180",
  "BEI": "#FFB6B6",
  "PEL": "#CCCCCC",
  "Pelindo": "#CCCCCC",
}
